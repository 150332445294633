





















































































// Vue
import Vue from "vue";
// Vuex
import { mapActions } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IClient } from "@/core/_models/client.model";
// Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import AdminService from "@/views/admin/Admin.service";
import { allDaysOfWeek } from "@/core/_constants/daysOfWeek.constant";

export default Vue.extend({
  name: "CreateSystemClientDialog",
  mixins: [validationMixin],
  props: ["nextIndex"],

  data() {
    return {
      dialog: false,
      isValid: false,
      isLoading: false,

      myFile: undefined,
      Name: "",
      CompanyLogo: "",
      MainThemeColor: "#10207C"
    };
  },

  validations: {
    Name: { required },
    MainThemeColor: { required }
  },

  methods: {
    ...mapActions("clients", {
      setClient: "setClient"
    }),

    submit(): void {
      const newSystemClient: IClient = {
        Name: this.Name,
        MainThemeColor: this.MainThemeColor,
        MainThemeTextColor: "#FFFFFF", // ! Do NOT remove this - needed for the mobile app.
        JobSchedulerTimeIntervals: 30,
        WorkingDays: [...allDaysOfWeek]
      };

      if (this.myFile) this.createClient(newSystemClient, this.myFile);
      else this.createClient(newSystemClient);
    },

    async createClient(newClient: IClient, logo?: File): Promise<void> {
      this.isLoading = true;

      const doc = firebase.default.firestore().collection("Client").doc();
      newClient.DocumentName = doc.id;

      // Create the new SystemClient.
      await this.setClient(newClient);
      AdminService.setupDefaults(newClient.DocumentName);

      if (logo) {
        const filePath = `Clients/${newClient.DocumentName}/CompanyLogo.${logo.name.split(".").pop()}`;

        const storageRef = firebase.default.storage().ref(filePath).put(logo);

        storageRef.on(
          "state_changed",
          (snaphot) => {
            // console.log((snaphot.bytesTransferred / snaphot.totalBytes) * 100);
          },
          (error) => {
            // console.log(error.message);
          },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
              newClient.CompanyLogo = url;

              await this.setClient(newClient);
              this.$emit("refresh-clients");
              this.isLoading = false;
              this.close();
            });
          }
        );
      } else {
        this.$emit("refresh-clients");
        this.isLoading = false;
        this.close();
      }
    },

    close(): void {
      this.Name = "";
      this.MainThemeColor = "#10207C";

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    NameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.Name.required && errors.push("Name is required");
      return errors;
    }
  }
});
