











































































































// Vue
import Vue from "vue";
// Vuex
import { mapActions, mapGetters } from "vuex";
// vue-draggable
import draggable from "vuedraggable";
// Models
import { Client, IClient } from "@/core/_models/client.model";
import { AuthUser } from "@/core/_models/authUser.model";
import { _Error } from "@/core/_models/_error.model";
// Services
import SystemService from "@/views/system/System.service";
import AuthService from "@/core/services/auth.service";
import AdminService from "@/views/admin/Admin.service";
// Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// Components
import CreateSystemClientDialog from "@/views/system/_dialogs/clients/CreateSystemClientDialog.vue";
import UpdateSystemClientDialog from "@/views/system/_dialogs/clients/UpdateSystemClientDialog.vue";
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "Clients",
  components: { draggable, CreateSystemClientDialog, ConfirmationDialog, UpdateSystemClientDialog },

  data() {
    return {
      search: "",
      isLoading: false,
      isSearching: false,

      clientsData: [] as IClient[]
    };
  },

  beforeMount() {
    this.getAllClients();
    this.fetchClients();
  },

  methods: {
    ...mapActions("clients", {
      fetchClients: "fetchClients",
      pushClients: "pushClients",
      setClient: "setClient",
      removeClient: "removeClient"
    }),

    getAllClients(): void {
      this.isLoading = true;

      SystemService.getAllClients().then((data: IClient[]) => {
        if (!data) return;
        this.clientsData = data;
        this.isLoading = false;
      });
    },

    resetSearch(): void {
      this.search = "";
      this.isSearching = false;
    },

    startImpersonation(client: Client): void {
      const email = process.env.VUE_APP_IMPERSONATION_EMAIL;
      const password = process.env.VUE_APP_IMPERSONATION_PASSWORD;

      AuthService.login(email, password).then((data: AuthUser | _Error) => {
        if (!data) return;
        if (data instanceof _Error) return;

        this.$store.dispatch("LOGOUT");

        const impersonationUser: AuthUser = data;

        impersonationUser.client = client;
        impersonationUser.clientId = client.DocumentName;
        this.$store.dispatch("LOGIN", impersonationUser);

        // Set the currentUser's Main Theme Color
        this.$vuetify.theme.themes.light.primary = AuthService.getMainThemeColor();

        // Update Job Dashboard and Calendar with impersonated Client's data
        this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
        this.$store.dispatch("SET_UPDATE_CALENDAR", true);
        this.$store.dispatch("SET_UPDATE_SCHEDULER", true);
      });
    },

    updateClient(index: number, updatedClient: Client): void {
      this.setClient(updatedClient).then(() => {
        this.getAllClients();
      });

      this.resetSearch();
    },

    deleteClient(clientToDelete: Client): void {
      this.removeClient(clientToDelete).then(() => {
        this.getAllClients();
      });

      this.resetSearch();
    }
  },

  computed: {
    ...mapGetters({
      items: "clients/items",
      currentUser: "currentUser"
    }),
    clients: {
      get(): any {
        return this.items;
      }
    },

    /**
     * Filters SystemClients via search bar
     * @author Nick Brahimir
     */
    filteredClients(): any {
      return this.clientsData.filter((element: IClient): any => {
        return element.Name.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    /**
     * Animations for Drag n Drop (see docs below)
     * https://www.npmjs.com/package/vuedraggable
     * https://sortablejs.github.io/Vue.Draggable/#/transition-example-2
     * https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
     * @author Nick Brahimir
     */
    dragOptions() {
      return {
        animation: 200,
        disabled: true, // Disabling DnD for SystemClients.
        ghostClass: "ghost" // Corresponds to the scoped scss class.
      };
    },

    dragOff() {
      return {
        disabled: true
      };
    }
  }
});
