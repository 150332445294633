















































































































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
// Models
import { IUser } from "@/core/_models/user.model";
// Services
import AdminService from "@/views/admin/Admin.service";

export default Vue.extend({
  name: "CreateUserDialog",
  props: {
    clientId: String
  },

  data() {
    return {
      dialog: false,
      isLoading: false,

      firstName: null as string | undefined | null,
      lastName: null as string | undefined | null,
      userType: null as string | undefined | null,
      email: null as string | undefined | null,
      password: null as string | undefined | null,

      userTypes: ["Dispatcher", "Driver", "Administrator"]
    };
  },

  mixins: [validationMixin],

  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
    userType: { required },
    password: { required, minLength: minLength(6) }
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE",
      ADD_INFO_MESSAGE: "ADD_INFO_MESSAGE"
    }),

    submit(): void {
      this.isLoading = true;

      const newUser: IUser = {
        ClientId: this.clientId ?? this.currentUser.clientId,
        UserType: this.userType!,
        FirstName: this.firstName!,
        LastName: this.lastName!,
        Email: this.email!,
        Password: this.password!
      };

      AdminService.createUser(newUser)
        .then(() => {
          this.isLoading = false;
          if (newUser.UserType === "Driver") this.$store.dispatch("SET_UPDATE_DASHBOARD", true);

          this.$emit("refresh-users");
          this.close();
          this.ADD_SUCCESS_MESSAGE(`User successfully created.`);
        })
        .catch((error) => {
          this.isLoading = false;
          this.ADD_ERROR_MESSAGE(`There was an error creating the user. Please try again.`);
          console.error(error);
        });
    },

    close(): void {
      this.firstName = null as string | undefined | null;
      this.lastName = null as string | undefined | null;
      this.userType = null as string | undefined | null;
      this.email = null as string | undefined | null;
      this.password = null as string | undefined | null;

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    /**
     * Parses any firstName errors.
     * @author Nick Brahimir
     */
    firstNameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First name is required");
      return errors;
    },

    /**
     * Parses any lastName errors.
     * @author Nick Brahimir
     */
    lastNameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last name is required");
      return errors;
    },

    /**
     * Parses any userTyoe errors.
     * @author Nick Brahimir
     */
    userTypeErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.userType.required && errors.push("User type is required");
      return errors;
    },

    /**
     * Parses any email errors.
     * @author Nick Brahimir
     */
    emailErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Email is not valid");
      return errors;
    },

    /**
     * Parses any passowrd errors.
     * @author Nick Brahimir
     */
    passwordErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength && errors.push("Password must be at least 6 characters long");
      return errors;
    }
  }
});
