
















































































































// Vue
import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IUser } from "@/core/_models/user.model";
// Services
import AdminService from "@/views/admin/Admin.service";
// Components
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "CreateUserDialog",
  components: { ConfirmationDialog },
  mixins: [validationMixin],
  props: {
    user: Object as PropType<IUser>
  },

  data() {
    return {
      dialog: false,
      isValid: false,
      isLoading: false,

      firstName: null as string | undefined | null,
      lastName: null as string | undefined | null,
      userType: null as string | undefined | null,
      email: null as string | undefined | null,

      userTypes: ["Dispatcher", "Driver", "Administrator"]
    };
  },

  validations: {
    firstName: { required },
    lastName: { required },
    userType: { required }
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    initialize(): void {
      if (this.user) {
        this.firstName = this.user.FirstName ?? null;
        this.lastName = this.user.LastName ?? null;
        this.userType = this.user.UserType ?? null;
        this.email = this.user.Email ?? null;
      }
    },

    /**
     * Submits the Updated User to the server and updates the list.
     * @author Nick Brahimir
     */
    submit(): void {
      this.isLoading = true;

      const updatedUser: IUser = {
        ClientId: this.user.ClientId,
        UserId: this.user.UserId,
        UserType: this.userType!,
        FirstName: this.firstName!,
        LastName: this.lastName!,
        Email: this.email
      };

      AdminService.updateUser(updatedUser)
        .then(() => {
          this.isLoading = false;
          if (updatedUser.UserType === "Driver") this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
          this.ADD_SUCCESS_MESSAGE(`User successfully updated.`);

          this.$emit("refresh-users");
          this.close();
        })
        .catch((error) => {
          this.isLoading = false;
          this.ADD_ERROR_MESSAGE(`User update failed. Please try again.`);
          console.error(error);
        });
    },

    deleteUser(user: IUser): void {
      this.isLoading = true;

      AdminService.deleteUser(user)
        .then(() => {
          this.isLoading = false;
          if (user.UserType === "Driver") this.$store.dispatch("SET_UPDATE_DASHBOARD", true);

          this.$emit("refresh-users");
          this.close();
          this.ADD_SUCCESS_MESSAGE(`User successfully deleted.`);
        })
        .catch((error) => {
          this.isLoading = false;
          this.ADD_ERROR_MESSAGE(`Failed to delete user. Please try again.`);
          console.error(error);
        });
    },

    /**
     * Clears the login form.
     * @author Nick Brahimir
     */
    close(): void {
      this.firstName = null as string | undefined | null;
      this.lastName = null as string | undefined | null;
      this.userType = null as string | undefined | null;
      this.email = null as string | undefined | null;

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    /**
     * Parses any firstName errors.
     * @author Nick Brahimir
     */
    firstNameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.firstName.required && errors.push("First name is required");
      return errors;
    },

    /**
     * Parses any lastName errors.
     * @author Nick Brahimir
     */
    lastNameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Last name is required");
      return errors;
    },

    /**
     * Parses any userTyoe errors.
     * @author Nick Brahimir
     */
    userTypeErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.userType.required && errors.push("User type is required");
      return errors;
    }
  }
});
