// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Models
import { IClient } from "@/core/_models/client.model";

const SystemService = {
  /**
   * Gets all Clients.
   *
   * @returns {Promise<IClient[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllClients(): Promise<IClient[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients`)
      .get()
      .then((querySnapshot) => {
        const data: IClient[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IClient;
          element.DocumentName = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  }
};

export default SystemService;
