










































































































































































































// Vue
import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IUser, User } from "@/core/_models/user.model";
import { IClient } from "@/core/_models/client.model";
// Services
import AdminService from "@/views/admin/Admin.service";
// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// Components
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import CreateUserDialog from "@/views/admin/_dialogs/users/CreateUserDialog.vue";
import UpdateUserDialog from "@/views/admin/_dialogs/users/UpdateUserDialog.vue";
import { allDaysOfWeek, DayOfWeek } from "@/core/_constants/daysOfWeek.constant";
import DaysSelector from "@/core/components/content/DaysSelector.vue";

export default Vue.extend({
  name: "UpdateSystemClientDialog",
  props: {
    client: Object as PropType<IClient>
  },
  components: { ConfirmationDialog, CreateUserDialog, UpdateUserDialog, DaysSelector },

  data() {
    return {
      dialog: false,
      isLoading: false,
      isConfirmed: false,

      search: "",

      name: "",
      companyLogo: "",
      mainThemeColor: "",
      myFile: null,
      workingDays: [] as DayOfWeek[],

      usersData: [] as IUser[]
    };
  },

  mixins: [validationMixin],

  validations: {
    mainThemeColor: { required },
    workingDays: { required }
  },

  methods: {
    ...mapActions("users", {
      fetchUsers: "fetchUsers",
      pushUsers: "pushUsers",
      setUser: "setUser",
      removeUser: "removeUser"
    }),

    ...mapActions("clients", {
      removeClient: "removeClient"
    }),

    initialize(): void {
      if (this.client) {
        this.name = this.client.Name;
        this.companyLogo = this.client.CompanyLogo ?? "";
        this.mainThemeColor = this.client.MainThemeColor ?? "";
        this.workingDays = this.currentUser.client.WorkingDays ?? allDaysOfWeek;
        this.getAllClientUsers();
      }
    },

    getAllClientUsers(): void {
      this.isLoading = true;

      AdminService.getAllClientUsers(this.client.DocumentName!).then((data: IUser[]) => {
        if (!data) return;

        // ! Remove the Impersonation User from the display
        const impersonationUser = data.find((element) => element.FirstName === "Impersonation");
        if (impersonationUser) {
          const index = data.indexOf(impersonationUser);
          data.splice(index, 1);
        }

        this.usersData = data;
        this.isLoading = false;
      });
    },

    submit(): void {
      this.isLoading = true;

      // Build payload
      const payload: IClient = {
        DocumentName: this.client.DocumentName,
        Name: this.name,
        CompanyLogo: this.companyLogo,
        MainThemeColor: this.mainThemeColor,
        MainThemeTextColor: "#FFFFFF",
        JobSchedulerTimeIntervals: this.client.JobSchedulerTimeIntervals,
        WorkingDays: this.workingDays
      };

      // Handles submission if a user uploads a new Company Logo
      if (this.myFile) {
        const uploadFile = this.myFile as unknown as File;
        const filePath = `Clients/${this.client.DocumentName}/CompanyLogo.${uploadFile.name
          .split(".")
          .pop()}`;

        const storageRef = firebase.default.storage().ref(filePath).put(uploadFile);

        storageRef.on(
          "state_changed",
          (snaphot) => {
            // console.log((snaphot.bytesTransferred / snaphot.totalBytes) * 100);
          },
          (error) => {
            // console.log(error.message);
          },
          () => {
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              payload.CompanyLogo = url;

              AdminService.updateClient(this.client.DocumentName!, payload).then((response: any) => {
                if (!response) return;

                this.$emit("refresh-clients");
                this.isLoading = false;
                this.close();
              });
            });
          }
        );
      }

      // Handles submission if the user didn't upload a Company Logo
      else {
        AdminService.updateClient(this.client.DocumentName!, payload).then((response: any) => {
          if (!response) return;

          this.$emit("refresh-clients");
          this.isLoading = false;
          this.close();
        });
      }
    },

    deleteClient(): void {
      this.removeClient(this.client).then(() => {
        this.$emit("refresh-clients");
      });
    },

    createUser(payload: User): void {
      this.setUser(payload).then(() => {
        this.getAllClientUsers();
        if (payload.UserType === "Driver") this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
      });
    },

    updateUser(payload: User): void {
      this.setUser(payload).then(() => {
        this.getAllClientUsers();
        if (payload.UserType === "Driver") this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
      });
    },

    deleteUser(payload: User): void {
      this.removeUser(payload).then(() => {
        this.getAllClientUsers();
        if (payload.UserType === "Driver") this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
      });
    },

    close(): void {
      this.name = "";
      this.companyLogo = "";
      this.mainThemeColor = "";
      this.myFile = null;

      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    filteredUsers(): any {
      return this.usersData.filter((element: IUser): any => {
        const fullName = element.FirstName + " " + element.LastName;
        const userType = element.UserType;

        return (
          fullName.toLowerCase().includes(this.search.toLowerCase()) ||
          userType.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },

    headers(): any[] {
      return [
        {
          text: "Last Name",
          value: "LastName",
          sortable: true
        },
        {
          text: "First Name",
          value: "FirstName",
          sortable: true
        },
        {
          text: "Role",
          value: "UserType",
          sortable: true
        },
        {
          text: "Email",
          value: "Email",
          sortable: true
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false
        }
      ];
    }
  }
});
